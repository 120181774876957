
import { defineComponent, ref, watch } from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue"
import BaseRadio from "@/components/base/form/BaseRadio.vue"
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue"
import Froala from "@/components/base/editor/Froala.vue"
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue"
import BaseSelect from "@/components/base/select/BaseSelect.vue"
import { ClaimContact } from "@/core/composite/composite"
import ClaimService from "@/core/services/ClaimService"

const LANGUAGES = [
  { value: 'EN', name: 'English' },
  { value: 'FR', name: 'French' }
]

const CLAIM_TYPES = [
  { value: 'MEDICAL', name: 'Medical' },
  { value: 'BAGGAGE_LOSS', name: 'Baggage Loss' },
  { value: 'TRIP_CANCEL', name: 'Trip Cancelation' },
  { value: 'VTC', name: 'VTC' }
]

export default defineComponent({
  name: "RequestDocuments",
  components: {
    BaseSelect,
    FormErrorMessage,
    Froala,
    BaseCheckBox,
    BaseRadio,
    BaseForm
  },

  props: {
    claim: {
      type: Object,
      required: true
    }
  },

  emits: ["saved"],

  setup(props) {
    const submitting = ref(false)
    const model = ref({
      contact: '',
      letter: '',
      language: 'EN',
      claimType: 'MEDICAL',
      invoiceAndReceipt: true,
      medicalRecords: true,
      proofDeparture: true,
      set30dayReminder: true,
      updateDocumentPending: true
    })

    // Watch for language changes to update letter content
    watch(() => model.value.language, () => {
      updateLetterContent()
    })

    const updateLetterContent = () => {
      if (model.value.language === 'FR') {
        generateFrenchLetter()
        return
      }

      generateEnglishLetter()
    }
    const generateFrenchLetter = () => {
      let letter = `
        <p>Cher/Chère ${props.claim.insured.person.name}</p>
        <p></p>
        <p>Veuillez trouver ci-joint un formulaire de réclamation et une liste des documents requis pour le traitement de votre réclamation.</p>
        <p>• Formulaire de réclamation complété et signé <a href="%s" download>(télécharger)</a></p>
      `

      if (model.value.invoiceAndReceipt) {
        letter += `
          <p>• Factures originales et reçus pour tous les paiements effectués</p>
          <p>• Preuve de paiement - relevé de carte de crédit indiquant tous les paiements effectués</p>
          <p>• Copie des factures du fournisseur (si le paiement n'a pas été effectué au moment du service)</p>
        `
      }

      if (model.value.medicalRecords) {
        letter += `
          <p>• Dossiers médicaux de l'établissement où les soins ont été reçus ou rapport du médecin</p>
          <p>• Les dossiers médicaux du médecin de famille peuvent être requis</p>
        `
      }

      if (model.value.proofDeparture) {
        letter += `
          <p>• Preuve de départ de votre province de résidence (ex: billets d'avion, reçu d'essence à la frontière)</p>
        `
      }

      letter += `
        <p></p>
        <p>Les documents peuvent être soumis par:</p>
        <p></p>
        <p>Courrier:<br>
        Trident Global Assistance<br>
        600- 21 Four Seasons Place<br>
        Etobicoke, ON, M9B 0A5</p>
        <p></p>
        <p>Ou courriel: <a href="mailto:claims@tridentassistance.com">claims@tridentassistance.com</a></p>
        <p></p>
        <p>Votre réclamation sera examinée conformément aux conditions de la police une fois tous les documents reçus.</p>
        <p>N'hésitez pas à nous contacter si vous avez des questions.</p>
      `

      model.value.letter = letter
    }

    const generateEnglishLetter = () => {
      let letter = `
        <p>Dear ${props.claim.insured.person.name}</p>
        <p></p>
        <p>Please find a claim form and a list of documents required for claim processing.</p>
        <p>• Completed and signed claim form <a href="%s" download>(download)</a></p>
      `

      if (model.value.invoiceAndReceipt) {
        letter += `
          <p>• Original invoices and receipts for all payments made</p>
          <p>• Proof of payment - credit card statement showing all payments made</p>
          <p>• Copy of invoices from the provider (if payment was not made at time of service)</p>
        `
      }

      if (model.value.medicalRecords) {
        letter += `
          <p>• Medical records from facility where medical attention was sought or doctor's report</p>
          <p>• Medical records from family physician may be required</p>
        `
      }

      if (model.value.proofDeparture) {
        letter += `
          <p>• Proof of departure from your province of residence (e.g. airline tickets, border gas receipt)</p>
        `
      }

      letter += `
        <p></p>
        <p>Documents can be submitted by:</p>
        <p></p>
        <p>Mail:<br>
        Trident Global Assistance<br>
        600- 21 Four Seasons Place<br>
        Etobicoke, ON, M9B 0A5</p>
        <p></p>
        <p>Or email: <a href="mailto:claims@tridentassistance.com">claims@tridentassistance.com</a></p>
        <p></p>
        <p>Your claim will be reviewed according to policy terms once all documents are received.</p>
        <p>Please contact us with any questions.</p>
      `

      model.value.letter = letter
    }

    return {
      model,
      submitting,
      LANGUAGES,
      CLAIM_TYPES,
      ...ClaimContact(props.claim.id),
      updateLetterContent
    }
  },

  methods: {
    onPreLoad() {
      this.loadClaimContact(this.claim.id)
      this.updateLetterContent()
    },

    async onSubmit() {
      this.submitting = true
      try {
        await ClaimService.sendRequestDocuments(this.claim.id, this.model)
        this.$emit('saved')
      } finally {
        this.submitting = false
      }
    }
  }
})
