
import {defineComponent, ref, watch} from 'vue'
import {Field} from "vee-validate";
import ProductService from "@/core/services/ProductService";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "ConfirmCoverage",
  components: {BaseRadio, Field,},
  props: {
    claim: {required: true, type: Object},
  },
  emits: ['saved'],
  setup(props) {
    const loading = ref(false);
    const model = ref({type: 'GH', email: 'ClientServiceOperations@grouphealth.ca'})
    const product = ref({});
    const load = (id) => {
      if (id) {
        ProductService.get<any>(id).then(res => {
          product.value = res;
        })
      } else {
        product.value = {id: '', name: ''};
      }
    }
    load(props.claim.policy.productId)
    watch(() => props.claim.policy.productId, cb => {
      load(cb)
    })
    return {
      loading,
      product,
      model,
    }
  },
  methods: {
    onChangeType() {
      if (this.model.type === 'GH') {
        this.model.email = 'ClientServiceOperations@grouphealth.ca'
      }
      if (this.model.type === 'GS') {
        this.model.email = 'claims@groupsource.ca'
      }
      if (this.model.type === 'GH_S') {
        this.model.email = 'payroll@grouphealth.ca;sue.cruikshank@grouphealth.ca'
      }
    },
    onSend() {
      this.loading = true;
      ClaimService.sendConfirmCoverage(this.claim.id, {email: this.model.email}).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.loading = false
      })
    }
  }
})

